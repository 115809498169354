import {
  faChartSimple,
  faLocationArrow,
  faLocationCrosshairs,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import {PUBLIC_ENV} from './env/getter';
import moment from 'moment';

export const linkedInLink = 'https://www.linkedin.com/company/sales-impact-academy';
export const facebookLink = 'https://www.facebook.com/SalesImpactAcademy/';
export const twitterLink = 'https://twitter.com/we_are_sia';

export const EMAIL_FROM_NAME = 'Sales Impact Academy';

export const CLASS_EVENT_SURVEY_ID_LEARNER = /staging|localhost/.test(
  PUBLIC_ENV.nextPublicAppHostUrl
)
  ? 'GolvyOY5'
  : 'Lwogt4BV';

export const CLASS_EVENT_CHECK_POINT_SURVEY_ID_LEARNER = /staging|localhost/.test(
  PUBLIC_ENV.nextPublicAppHostUrl
)
  ? 'q8CSc2xO'
  : 'sBgAPElD';

export const CLASS_EVENT_SURVEY_ID_COACH = /staging|localhost/.test(PUBLIC_ENV.nextPublicAppHostUrl)
  ? 'pUHb4S77'
  : 'VvYM3BJc';

export const TAGS_MAP = [
  {
    slug: 'ae',
    icon: faChartSimple,
    primaryColor: '#FF5E84',
    contrastColor: '#fff'
  },
  {
    slug: 'cs',
    icon: faStar,
    primaryColor: '#F9C16F',
    contrastColor: '#fff'
  },
  {
    slug: 'sdr',
    icon: faLocationCrosshairs,
    primaryColor: '#1AC099',
    contrastColor: '#fff'
  },
  {
    slug: 'mgmt',
    primaryColor: '#BE48E7',
    contrastColor: '#fff',
    icon: faLocationArrow
  }
];

// Legacy Learn screen
export const DATE_FORMAT_MEMBER_SINCE = 'MMMM YYYY';
export const DATE_FORMAT_MY_LEARNING_CARD = 'MMM D';
export const DATE_FORMAT_COMPLETED_AT = 'MMM D, YYYY';

// Legacy Discover screen
export const DATE_FORMAT_LEGACY_DISCOVER_CARD = 'MMM D';

// Legacy Upcoming screen
export const DATE_FORMAT_UPCOMING_CIRCLE_MONTH = 'MMM';
export const DATE_FORMAT_UPCOMING_CIRCLE_DAY = 'D';

// Cohort dates (for breadcrumbs, enrollments & cards)
export const DATE_FORMAT_COHORT_DATES = 'MMM D'; // and breadcrumb start date
export const DATE_FORMAT_COHORT_DATES_WITH_YEAR = 'MMM D, YYYY';

// New Learn screen
export const DATE_FORMAT_LEARN_CARD = 'ddd, MMM Do';
export const DATE_FORMAT_ENROLLED_AT = 'MMM D, YYYY';
export const DATE_FORMAT_TASK = 'ddd, MMM Do';

// New Discover screen
export const DATE_FORMAT_DISCOVER_CARD = 'ddd, MMM Do';

// New Upcoming screen
export const DATE_FORMAT_AVAILABLE_SESSIONS = 'dddd, MMMM D';
export const CALENDAR_SPECS: moment.CalendarSpec = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: DATE_FORMAT_AVAILABLE_SESSIONS,
  lastDay: DATE_FORMAT_AVAILABLE_SESSIONS,
  lastWeek: DATE_FORMAT_AVAILABLE_SESSIONS,
  sameElse: DATE_FORMAT_AVAILABLE_SESSIONS
};

// Coach screen
export const DATE_FORMAT_UPCOMING_COACHING_CARD = 'ddd, MMM Do';

// Schedule tasks
export const DATE_FORMAT_SCHEDULE_TASKS = 'MMM D';
export const DATE_FORMAT_LIGHTNING_SESSION_SCHEDULE_TASK = 'ddd, MMM Do';

// Task due dates (universal)
export const DATE_FORMAT_TASK_DUE = 'MMM D';
export const DATE_FORMAT_IA_ATTEMPTED_AT = 'MMM D';

// Emails
export const DATE_FORMAT_EMAIL_COHORT_DATE = 'MMM Do';

// Stream messages
export const DATE_FORMAT_MESSAGE = 'MMM D, YYYY';

// Time (universal)
export const TIME_FORMAT = 'h:mm A z';

export const LIMITED_SEATS_THRESHOLD = 10;

// Managers coaching sessions
export const DATE_FORMAT_COACHING_SESSION = 'ddd, MMM DD';

export const FUTURE_SESSION_DATE = `ddd, MMM DD`;

export const CREDIT_SUBSCRIPTION_WINDOW_SIMPLIFIED = 'MMMM D';
export const CREDIT_SUBSCRIPTION_WINDOW_COMPLETE = 'MMMM D, YYYY';

export const SIA_ORG_ID = '7d63d01c-3cbe-43c5-8c4a-b102f2050283';

export const MAX_INTERACTIONS_FOR_PILOT_ON_DEMAND_COACHING = 4;
